import React from "react"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import Navbar from "../components/LinkedInPowertools/NavbarLIP"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import MyAccountDetails from "../components/MyAccount/MyAccountDetails"

const Details = () => {
  return (
    <AuthWrapper>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle="Your Account"
          homePageText="Home"
          homePageUrl="/"
          activePageText="Your Account"
        />

        <MyAccountDetails />
        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default Details
